import React from 'react';
import Head from 'next/head';

const Metadades = (props) => {
  const {indexIfembedded} = props;
  return (
    <Head>
      {indexIfembedded ? <meta name='googlebot' content='noindex,indexifembedded'></meta> 
      : <meta name="robots" content="noindex"></meta>}
    </Head>
  );
};

export default Metadades;
